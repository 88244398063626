import React from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './style.module.scss';
import localize from '../../localization';
import { currentUser } from '../../models/currentUser';

export default function LimitsPanel() {
  const { t, getLocale } = useTranslation();

  const accountLimit = currentUser.value?.accountLimit;

  const limitRefreshPeriod =
    accountLimit?.refreshPeriod &&
    new Date(accountLimit?.nextRefresh as unknown as number).toLocaleString(getLocale(), {
      month: 'long',
      day: 'numeric',
    });

  if (!accountLimit || accountLimit.remainingTokenLimit === undefined || accountLimit.tokenLimit === undefined)
    return null;

  const panelType = accountLimit.remainingTokenLimit === 0 ? 'error' : 'warning';

  return (
    <div
      data-test-id='TopPanel.Payment'
      className={cn(styles.topPanel, styles.topPanel_payment, {
        [styles.topPanel_failed]: panelType === 'error',
      })}
    >
      <p>
        {t(`Limits:${panelType}`, {
          amount: (accountLimit.remainingTokenLimit / 1000).toLocaleString(getLocale(), {
            maximumFractionDigits: 3,
          }),
          pluralString: localize.translateWithPlural('jayToken', Number(accountLimit.tokenLimit)),
          date: limitRefreshPeriod,
        })}{' '}
        <a href={`${t('docsUrl')}/limits/`} target='_blank' rel='noopener noreferrer'>
          {t('LimitsMore')}
        </a>
      </p>
    </div>
  );
}
