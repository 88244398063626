import { ConversationResponse, TemplateResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { format } from 'date-fns';

import { CategoryValue } from '../../components/Agents/Categories';
import { appsAdapterService } from '../../services/service';
import { AgentType } from '../../types/agents';
import { Conversation } from '../../types/chat';
import { DEFAULT_AGENT_CONFIG } from '../../utils/app/const';
import { processConversationContext, processHistory } from '../../utils/app/conversation';
import { findConversationIndex } from './index';
import { setConversationsValue } from './signals';
import localize from '../../localization';

export const rawConversationToConversation = (
  rawChat: ConversationResponse,
  chatTemplate: TemplateResponse
): Conversation => {
  const chatName =
    rawChat.app.template === 'main'
      ? localize.translate('assistantJay:old')
      : rawChat.name ||
        `${chatTemplate.info?.title || rawChat.app.template || 'Assistant'} - ${format(
          rawChat.createdAt || new Date(),
          'HH:mm dd.MM'
        )}`;

  const processedHistory = processHistory(rawChat.history);
  const processedContext = processConversationContext(processedHistory);
  return {
    ...rawChat,
    name: chatName,
    config: {
      ...DEFAULT_AGENT_CONFIG,
      ...rawChat.app,
      categories: chatTemplate.categories as CategoryValue[],
      params: rawChat.app.params as AgentType['params'],
      info: chatTemplate.info,
      schema: {},
    },
    history: processedHistory,
    contextValue: processedContext,
  };
};

export const setMessageIsStreaming = (add: boolean, chatId: string) => {
  setConversationsValue(prevValue => {
    const updateIndex = findConversationIndex(chatId);
    if (updateIndex > -1) {
      prevValue[updateIndex].messageIsStreaming = add;
      if (!add) {
        if (window.location.pathname.includes(prevValue[updateIndex].id)) {
          appsAdapterService.clearUnreadChanges(prevValue[updateIndex].id);
        } else {
          prevValue[updateIndex].hasUnreadChanges = true;
        }
      }
    }
  });
};
