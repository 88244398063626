export const chatLocalization = {
  maxFilesInfo: {
    eng: 'You can upload up to $[1] files',
    ru: 'Можно загружать до $[1] файлов',
  },
  accessDenied: {
    eng: 'Access denied',
    ru: 'Доступ запрещен',
  },
  settings: {
    eng: 'Settings',
    ru: 'Настройки',
  },
  appSettings: {
    eng: 'Application settings',
    ru: 'Настройки приложения',
  },
  deleteChat: {
    eng: 'Remove dialog',
    ru: 'Удалить диалог',
  },
  newChat: {
    eng: 'New chat',
    ru: 'Новый чат',
  },
  typeMessage: {
    eng: 'Type a message',
    ru: 'Напишите сообщение',
  },
  fileUpload: {
    eng: 'Click to select a file or drag it here',
    ru: 'Нажмите, чтобы выбрать файл, или перетащите его сюда',
  },
  fileAccepted: {
    eng: 'Allowed file formats: ',
    ru: 'Допустимые форматы файлов: ',
  },
  fileSize: {
    eng: 'Maximum file size: {size} MB',
    ru: 'Максимальный размер файла: {size} Мб',
  },
  'ChatInput:StopGenerating': {
    eng: 'Cancel my request',
    ru: 'Отменить запрос',
  },
  'ChatInput:RecordPaused': {
    eng: '',
    ru: 'Запись остановлена',
  },
  processingRequest: {
    eng: 'Please wait a bit. Soon the applet will be ready and will give you an answer.',
    ru: 'Подождите, пожалуйста, немного. Скоро приложение будет готово и даст вам ответ.',
  },
  defaultMsg: {
    eng: 'I’m ready! Send me any requests and I’ll try to answer.',
    ru: 'Готово! Отправь мне любой запрос, а я постараюсь на него ответить.',
  },
  chatLoaderText: {
    eng: 'Preparing a response',
    ru: 'Готовлю ответ',
  },
  noMoreThan3Requests: {
    eng: '#{config.productName} cannot process more than 3 requests at the same time. Please wait for responses from other applications, then send your request.',
    ru: '#{config.productName} не может обрабатывать больше 3 запросов одновременно. Пожалуйста, дождитесь ответа от других приложений, а затем отправьте запрос.',
  },
  noMoreThan3RequestsToCreateApp: {
    eng: '#{config.productName} cannot process more than 3 requests at the same time. Please wait for responses from other applications, then you will be able to create a new application.',
    ru: '#{config.productName} не может обрабатывать больше 3 запросов одновременно. Пожалуйста, дождитесь ответа от других приложений, затем вы сможете создать новое приложение.',
  },
  chatCancelReqText: {
    eng: 'Okay! I’m no longer working on this request. I’m waiting for new messages.',
    ru: 'Хорошо! Больше над этим запросом не работаю. Жду новых сообщений.',
  },
  'Chat:Template:Error': {
    eng: 'This application is no longer available',
    ru: 'Это приложение больше недоступно',
  },
  'ChatHeader:context': {
    eng: 'Context',
    ru: 'Контекст',
  },
  'ChatHeader:clearHistory': {
    eng: 'Reset',
    ru: 'Сбросить',
  },
  'ChatHeader:contextTooltip': {
    eng: 'The context is your preceding conversation with AI models. It’s sent with each of your requests. This helps the model understand you better, but it increases the cost of requests. When the context is filled, new messages replace the old ones.',
    ru: 'Контекст — это ваш предшествующий диалог с нейросетью. Он отправляется с каждым вашим запросом. Это помогает нейросети лучше вас понимать, но увеличивает стоимость запросов. Когда контекст заполняется, новые сообщения заменяют старые.',
  },
  'ClearHistoryModal:Title': {
    eng: 'Clear history',
    ru: 'Очистить историю',
  },
  'ClearHistoryModal:Text': {
    eng: 'All messages in the dialog will be deleted. This will also reset the context: the application will start responding to your requests without taking previous messages into account.',
    ru: 'Все сообщения в диалоге будут удалены. При этом также будет сброшен контекст: приложение начнет отвечать на ваши запросы без учета прошлых сообщений.',
  },
  'ChatMessage:you': {
    eng: 'You',
    ru: 'Вы',
  },
  'ChatMessageButton:Regenerate': {
    eng: 'Regenerate',
    ru: 'Перегенерировать',
  },
  'ChatMessageButton:Copy': {
    eng: 'Copy',
    ru: 'Скопировать',
  },
  'ChatMessageButton:Copied': {
    eng: 'Copied',
    ru: 'Скопировано',
  },
  'ChatMessageButton:Delete': {
    eng: 'Delete',
    ru: 'Удалить',
  },
  'ChatMessage:isMasked': {
    eng: 'Data is masked',
    ru: 'Данные маскированы',
  },
  'AutoContextReset:contextReset': {
    eng: 'Conversation context automatically reset',
    ru: 'Контекст диалога автоматически сброшен',
  },
  'SystemAppUpdate:contextReset': {
    eng: 'Conversation context reset',
    ru: 'Контекст диалога сброшен',
  },
  'SystemAppUpdate:appUpdated': {
    eng: 'Settings updated, conversation context reset',
    ru: 'Настройки приложения обновлены, контекст диалога сброшен',
  },
  'Chat:FileSettings:error': {
    eng: 'Failed to load PDF file',
    ru: 'Не удалось загрузить PDF-файл',
  },
  'Chat:FileSettings:loading': {
    eng: 'Loading PDF…',
    ru: 'Загрузка PDF…',
  },
  'Chat:FileSettings:noFile': {
    eng: 'No PDF file specified',
    ru: 'PDF-файл не указан',
  },
  'ChatInput:MicDisabledTooltip': {
    eng: 'Microphone not found',
    ru: 'Микрофон не найден',
  },
  'ChatInput:MicDisabledTooltip:mobile': {
    eng: 'Microphone not detected',
    ru: 'Микрофон не обнаружен',
  },
  'ChatInput:RecordTooltip': {
    eng: 'Click to start voice input',
    ru: 'Нажмите, чтобы начать голосовой ввод',
  },
};
