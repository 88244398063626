import React, { useCallback, useMemo, useState } from 'react';

import {
  Expenses,
  ExpensesHistoryResponse,
  Replenishment,
  ReplenishmentHistoryResponse,
} from '@just-ai/api/dist/generated/CopilotGateway';
import { TokenType } from '@just-ai/api/dist/generated/CopilotGateway';
import { useTranslation, InputGroup, InputText, IconButtonProps, useDebounce } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './styles.module.scss';
import { DrawerData } from '../../tabs/AnalyticsTab';
import { GroupExpensesByDay, GroupReplenishesByDay } from '../utils';

const searchIcon: IconButtonProps[] = [{ color: 'secondary', name: 'farSearch' }];

export const AnalyticsTable = ({
  data,
  openDrawer,
  dataType,
  searchHandler,
  searchVal,
  handleChatClick,
}: {
  data?: ExpensesHistoryResponse | ReplenishmentHistoryResponse;
  openDrawer?: (value?: DrawerData) => void;
  dataType: 'users' | 'chats' | 'replenish';
  searchHandler?: (search: string) => void;
  searchVal?: string;
  handleChatClick?: (chat: Expenses['conversation']) => void;
}) => {
  const { t, getLocale } = useTranslation();
  const [searchLocal, setSearchLocal] = useState(searchVal || '');

  const expensesGroupedByDay: Array<Expenses> = useMemo(() => {
    if (data && 'expenses' in data) {
      return GroupExpensesByDay(data.expenses);
    }
    return [];
  }, [data]);

  useDebounce(searchLocal, 500, searchHandler);

  const replenishesGroupedByDay: Array<Omit<Replenishment, 'timestamp'> & { timestamp?: Date }> = useMemo(() => {
    if (data && 'replenishments' in data) {
      return GroupReplenishesByDay(data.replenishments);
    }
    return [];
  }, [data]);

  const getTableContent = useCallback(() => {
    if (!data || data.page.totalElements === 0) return null;

    if (replenishesGroupedByDay.length) {
      return replenishesGroupedByDay.map((replenish, index) => (
        <React.Fragment key={`replenish_${index}`}>
          {replenish.timestamp && (
            <tr className={styles.Table__heading}>
              <td>
                {new Date(replenish.timestamp).toLocaleString(getLocale(), {
                  day: 'numeric',
                  month: 'long',
                })}
              </td>
              <td></td>
            </tr>
          )}
          <tr className={styles.Table__data}>
            <td>
              {t(
                `Analytics:TokenReplenishType:${
                  TokenType[replenish.tokenType.charAt(0) + replenish.tokenType.toLowerCase().slice(1)]
                }`
              )}
            </td>
            <td data-test-id={`replenish_${index}`} className={cn(styles.Table__fitWidth, 'color-success')}>{`+ ${t(
              'Tokens',
              {
                tokenAmount: replenish.tokenAmount.toLocaleString(getLocale(), { maximumFractionDigits: 3 }),
              }
            )}`}</td>
          </tr>
        </React.Fragment>
      ));
    }
    if (dataType === 'users' && expensesGroupedByDay.length && openDrawer) {
      return expensesGroupedByDay.map((expense, index) => (
        <React.Fragment key={`expense_${index}`}>
          {index < 1 && (
            <tr className={styles.Table__heading}>
              <td>{t('User')}</td>
              <td className={styles.Table__fitWidth}>{t('Expenses')}</td>
            </tr>
          )}
          <tr
            className={cn(styles.Table__data, 'cursor-pointer')}
            key={expense.user?.id}
            onClick={() => openDrawer(expense.user)}
            data-test-id={`expense_${index}`}
          >
            <td>
              <div className='flex flex-col'>
                <p>{expense.user?.email}</p>
                <p className={styles.Table__secondary}>{expense.user?.name}</p>
              </div>
            </td>
            <td className={styles.Table__fitWidth}>{`- ${t('Tokens', {
              tokenAmount: expense.tokenAmount.toLocaleString(getLocale(), { maximumFractionDigits: 3 }),
            })}`}</td>
          </tr>
        </React.Fragment>
      ));
    }

    if (dataType === 'chats' && expensesGroupedByDay.length)
      return expensesGroupedByDay.map((expense, index) => (
        <React.Fragment key={`expense_${index}`}>
          {expense.date && (
            <tr className={styles.Table__heading}>
              <td>{new Date(expense.date).toLocaleString(getLocale(), { day: 'numeric', month: 'long' })}</td>
              <td></td>
            </tr>
          )}
          {expense.conversation &&
            [expense.conversation].map(conversation => {
              return (
                <tr data-test-id={`chat_${index}`} className={styles.Table__data} key={conversation.id}>
                  <td
                    onClick={() => openDrawer && openDrawer({ selectedChat: conversation })}
                    className={cn(styles.Table__link)}
                  >
                    {conversation.name}
                  </td>
                  <td className={styles.Table__fitWidth}>{`- ${t('Tokens', {
                    tokenAmount: expense.tokenAmount.toLocaleString(getLocale(), { maximumFractionDigits: 3 }),
                  })}`}</td>
                </tr>
              );
            })}
        </React.Fragment>
      ));
  }, [data, replenishesGroupedByDay, dataType, expensesGroupedByDay, openDrawer, getLocale, t]);

  return (
    <>
      {dataType === 'users' && searchHandler && (
        <InputGroup PrependInner={searchIcon} className={styles.Table__search}>
          <InputText value={searchLocal} onChange={setSearchLocal} placeholder={t('Analytics:Search:Placeholder')} />
        </InputGroup>
      )}
      {!data || data.page.totalElements === 0 ? (
        <div className={styles.Table__empty}>
          {t(
            dataType === 'replenish'
              ? 'Analytics:Replenish:Empty'
              : searchLocal
                ? 'Analytics:Expenses:Empty:Search'
                : 'Analytics:Expenses:Empty'
          )}
        </div>
      ) : (
        <table className={styles.Table}>
          <tbody>{getTableContent()}</tbody>
        </table>
      )}
    </>
  );
};
