import React, { memo, SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Banner, Spinner, usePrevious, useTranslation } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import { useComputed, useSignal } from '@preact/signals-react';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { AgentSettings } from 'components/Settings/AgentSettingsSidebar';
import { cloneDeep, uniqueId } from 'lodash';
import {
  conversations,
  conversationsLoaded,
  setConversationsValue,
  setJGuardFailureToProtect,
} from 'models/conversations/signals';
import { ContentContainerWithRightBar } from 'pages/FullContainer/ContentContainer';

import { CanvasHolder } from './CanvasHolder';
import { CanvasHolderContext } from './CanvasHolderContext/CanvasHolderContext';
import ChatHeader from './ChatHeader/ChatHeader';
import { ChatInput } from './ChatInput';
import { ChatLoader } from './ChatLoader';
import { ChatMessage } from './ChatMessage';
import { MAX_FILE_SIZE_CHAT } from './consts';
import { FileSettings } from './FileSettings/FileSettings';
import { useFileNameHeader } from './getFileName.hook';
import styles from './style.module.scss';
import { useUpdateExternalInstancesState } from './useUpdateExternalInstancesState.hook';
import AppContext from '../../contexts/appContext';
import { findConversationIndex } from '../../models/conversations';
import { getUserChatHistory, streamMessage } from '../../models/conversations/apiHooks';
import { currentUser, updateCurrentUserLimit } from '../../models/currentUser';
import { guideTourEvent$ } from '../../modules/GuideTourStepper/guideTourEvents';
import { goToMain, useConversationId } from '../../routes';
import useApiService from '../../services/useApiService';
import { Conversation, Message } from '../../types/chat';
import { JustSessionUserData } from '../../types/currentUser';
import { AgentApiError } from '../../types/errors';
import { isMobile } from '../../utils/app/common';
import { isConversationTemplateValid, processConversationContext, processHistory } from '../../utils/app/conversation';

export const Chat = memo(() => {
  const { t } = useTranslation();
  const updateFileInCanvasAppInstance = useRef<(instanceId: string, url: string) => void>(() => {});

  const paramsSignal = useConversationId();
  const selectedConversation = useComputed(() => {
    return conversations.value.find(conversation => conversation.id === paramsSignal.value);
  });
  const updateExternalInstancesState = useUpdateExternalInstancesState(
    updateFileInCanvasAppInstance,
    selectedConversation.value?.id
  );

  const isCanvasChat = selectedConversation.value?.isCanvasChat || false;
  const fileName = useFileNameHeader(selectedConversation.value);

  const previousSelectedConversation = usePrevious(selectedConversation.value);

  useEffect(() => {
    if (selectedConversation.value) guideTourEvent$.next(`ChatOpened:${selectedConversation.value?.app.template}`);
  }, [selectedConversation]);

  const requestWasStopped = useSignal(false);

  useEffect(() => {
    if (
      previousSelectedConversation &&
      selectedConversation.value &&
      selectedConversation.value.id !== previousSelectedConversation.id
    ) {
      requestWasStopped.value = false;
    }
  }, [selectedConversation.value, previousSelectedConversation, requestWasStopped]);

  const {
    state: { lightMode },
    addAlert,
  } = useContext(AppContext);

  const {
    sendMessageToChat,
    sendUserActionToAnalytics,
    getUserChat,
    getChatHistory,
    cancelMessageProcessing,
    validateCurrentJGuardKey,
    sendAudioToTTS,
  } = useApiService();

  const [showScrollDownButton, setShowScrollDownButton] = useState<boolean>(false);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleScrollDown = useCallback((value?: number | SyntheticEvent) => {
    if (value && typeof value === 'number') {
      return chatContainerRef.current?.scrollBy({ top: value, behavior: 'smooth' });
    }
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, []);

  const handleSend = useCallback(
    async (message: Message, audio?: Blob) => {
      if (!selectedConversation.value) return;
      requestWasStopped.value = false;
      let localSelectedConversation = cloneDeep(selectedConversation.value);
      let updatedConversation: Conversation = {
        ...localSelectedConversation,
        history: [...localSelectedConversation.history, message],
        messageIsStreaming: true,
      };
      setConversationsValue(prevConvs => {
        let indexToUpdate = findConversationIndex(localSelectedConversation.id);
        if (indexToUpdate > -1) prevConvs[indexToUpdate] = updatedConversation;
      });

      sendUserActionToAnalytics({
        eventName: 'Request',
        eventValue: {
          type: localSelectedConversation.app.template === 'toolAssistant' ? 'mainApp' : 'app',
          name: localSelectedConversation.config.template,
        },
      });

      await updateExternalInstancesState();

      let result;

      try {
        if (audio) {
          result = await sendAudioToTTS({
            conversationId: localSelectedConversation.id,
            audio,
          });
          //после получения ответа от ТТС добавляем расшифрованный текст сообщения в чат
          updatedConversation = {
            ...localSelectedConversation,
            history: [
              ...localSelectedConversation.history,
              {
                ...message,
                content: [...message.content, { type: 'text', text: result.speechToTextMessage }],
              },
            ],
            messageIsStreaming: true,
          };
          setConversationsValue(prevConvs => {
            let indexToUpdate = findConversationIndex(localSelectedConversation.id);
            if (indexToUpdate > -1) prevConvs[indexToUpdate] = updatedConversation;
          });
        } else {
          updatedConversation = cloneDeep(updatedConversation);
          result = await sendMessageToChat(localSelectedConversation.id, message.content);
        }
        streamMessage(localSelectedConversation.id);
        if ('meta' in result?.data && result?.data?.meta['isProcessing']) {
          setConversationsValue(prevConvs => {
            let indexToUpdate = findConversationIndex(localSelectedConversation.id);
            if (indexToUpdate > -1) prevConvs[indexToUpdate].status = 'BUILDING';
          });
        }
        //после отправки сообщения в хедерах приходят данные об обновлении лимита токенов пользователя
        if (currentUser.value && currentUser.value.accountLimit) {
          const responseHeaders = result.headers;
          const updatedAccountLimit: JustSessionUserData['accountLimit'] = {
            tokenLimit: Number(responseHeaders['x-user-rate-limit']) || 0,
            nextRefresh: (Number(responseHeaders['x-user-rate-limit-next-refresh']) as unknown as Date) || 0,
            remainingTokenLimit: Number(responseHeaders['x-user-rate-limit-remaining']) || 0,
            refreshPeriod: currentUser.value?.accountLimit?.refreshPeriod,
          };
          updateCurrentUserLimit({ ...currentUser.value?.accountLimit, ...updatedAccountLimit });
        }
      } catch (error) {
        if ((error as AxiosError).code) {
          AppLogger.error({
            message: `Error sending message in Chat`,
            exception: error as AxiosError,
          });

          setConversationsValue(prevConvs => {
            let indexToUpdate = findConversationIndex(localSelectedConversation.id);
            if (indexToUpdate > -1) prevConvs[indexToUpdate] = { ...updatedConversation, messageIsStreaming: false };
          });
        }
        const response = (error as AxiosError<AgentApiError>)?.response;

        if (response?.status === 400 && response.data.error === 'gateway.voice_message.empty') {
          return addAlert(t('emptyVoiceMessageError'));
        }

        if (response?.status === 413) {
          return addAlert(t('fileSizeError__param', { size: MAX_FILE_SIZE_CHAT }), 'error');
        }
        if (response?.status === 402 && response.data.error === 'gateway.balance.not_enough_tokens') {
          return;
        }
        if (response?.data.error === 'gateway.common.timeout') {
          return addAlert(t('timeoutError'), 'error');
        }
        if ((error as AxiosError).code && (error as AxiosError).code !== AxiosError.ERR_CANCELED) {
          addAlert(t(response?.data.error ?? 'defaultError', response?.data.args ?? {}), 'error');
        }
      }
    },
    [
      selectedConversation.value,
      requestWasStopped,
      sendUserActionToAnalytics,
      updateExternalInstancesState,
      sendAudioToTTS,
      sendMessageToChat,
      addAlert,
      t,
    ]
  );

  const handleSendById = useCallback(
    (messageId: string) => {
      if (!selectedConversation.value) return;
      const message = selectedConversation.value.history.find(message => message.id === messageId);
      if (message) {
        handleSend({ ...message, id: uniqueId(), createdAt: Date.now(), updatedAt: Date.now() });
      }
    },
    [handleSend, selectedConversation.value]
  );

  const handleCancelSend = useCallback(async () => {
    if (selectedConversation.value) {
      requestWasStopped.value = true;
      selectedConversation.value.streamSubscription?.unsubscribe();

      const conversationIndexToUpdate = findConversationIndex(selectedConversation.value.id);
      setConversationsValue(prevConversations => {
        const conversation = prevConversations[conversationIndexToUpdate];
        conversation.streamSubscription = undefined;
        const deleteCount = conversation.history.at(-1)?.type === 'response' ? 2 : 1;
        conversation.history.splice(-deleteCount, deleteCount);
        conversation.messageIsStreaming = false;
      });

      await cancelMessageProcessing(selectedConversation.value.id);
    }
  }, [selectedConversation.value, requestWasStopped, cancelMessageProcessing]);

  const handleHistoryFetch = useCallback(async () => {
    if (!selectedConversation.value || loadingHistory) return;
    let localSelectedConversation = cloneDeep(selectedConversation.value);
    let updatedConversation: Conversation;
    if (
      !localSelectedConversation.history ||
      !localSelectedConversation.history.length ||
      localSelectedConversation.history.length < 20
    )
      return;
    const oldestMessageTimestamp = localSelectedConversation.history[0].createdAt;

    try {
      setLoadingHistory(true);
      const { data: newHistory } = await getChatHistory(localSelectedConversation.id, oldestMessageTimestamp);
      const updatedHistory = [...processHistory(newHistory.messages || []), ...localSelectedConversation.history];
      updatedConversation = {
        ...localSelectedConversation,
        history: updatedHistory,
        contextValue: processConversationContext(updatedHistory),
      };
      setConversationsValue(prevConvs => {
        let indexToUpdate = findConversationIndex(localSelectedConversation.id);
        if (indexToUpdate > -1) prevConvs[indexToUpdate] = updatedConversation;
        else prevConvs[0] = updatedConversation;
      });
    } catch (error) {
    } finally {
      setLoadingHistory(false);
    }
  }, [getChatHistory, loadingHistory, selectedConversation.value]);

  const handleScrollTop = useCallback(() => {
    if (chatContainerRef.current) {
      const { scrollTop } = chatContainerRef.current;
      if (scrollTop === 0) {
        handleHistoryFetch();
      }
    }
  }, [handleHistoryFetch]);

  const handleScroll = useCallback(() => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const bottomTolerance = 30;

      if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
        setShowScrollDownButton(true);
      } else {
        setShowScrollDownButton(false);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedConversation && selectedConversation.value?.newMessage) {
      handleSend(selectedConversation.value.newMessage);
      selectedConversation.value.newMessage = undefined;
    }
  }, [handleSend, selectedConversation]);

  useEffect(() => {
    const updateSelectedConversation = async () => {
      if (!selectedConversation.value?.id && selectedConversation.value?.status !== 'BUILDING') return;
      await getUserChatHistory(selectedConversation.value.id, selectedConversation.value.config.template);
      streamMessage(selectedConversation.value.id);
      // we validate DataGuard protection status on every chat loading to avoid using polling, if no DataGuard key was ever set up, it should always return true
      try {
        const { data: isJGuardKeyValid } = await validateCurrentJGuardKey();
        setJGuardFailureToProtect(!isJGuardKeyValid);
      } catch (error) {
        setJGuardFailureToProtect(true);
      }
    };
    if (
      (!selectedConversation.value?.history || selectedConversation.value?.history.length < 1) &&
      !selectedConversation.value?.messageIsStreaming &&
      previousSelectedConversation?.id !== selectedConversation.value?.id
    ) {
      updateSelectedConversation();
    }
  }, [
    getUserChat,
    previousSelectedConversation?.id,
    selectedConversation.value?.status,
    selectedConversation.value?.config.template,
    selectedConversation.value?.history,
    selectedConversation.value?.id,
    t,
    selectedConversation.value?.messageIsStreaming,
    validateCurrentJGuardKey,
  ]);

  if (conversationsLoaded.value && !selectedConversation.value) goToMain();

  if (!selectedConversation.value)
    return (
      <div className={cn(`relative flex-1 overflow-hidden ${lightMode}`, styles.chat__wrapper)}>
        <div className='h-full flex'>
          <Spinner />
        </div>
      </div>
    );

  const params = selectedConversation.value?.config?.params;

  const paramKeys = Object.keys(params || {});

  const onlyParamIsFile = !!params && paramKeys.length === 1 && paramKeys[0] === 'document';

  const hasAgentSettings = paramKeys.length > 0;
  const isMainChat = selectedConversation.value.app.template === 'toolAssistant';
  const isFileAnalysisChat = fileName.endsWith('pdf');

  const lastMessageInHistory = selectedConversation.value.history.at(-1);

  const showLoader = ['painter', 'qna'].includes(selectedConversation.value.app.template)
    ? selectedConversation.value.messageIsStreaming
    : !requestWasStopped.value &&
      selectedConversation.value.messageIsStreaming &&
      (!lastMessageInHistory ||
        ['request', 'system'].includes(lastMessageInHistory?.type) ||
        (lastMessageInHistory?.type === 'response' && !lastMessageInHistory?.content?.[0]?.['text']));

  const isError = !isConversationTemplateValid(selectedConversation.value.config);
  const isFullWidth = isFileAnalysisChat ? false : !hasAgentSettings || isMainChat || onlyParamIsFile || isError;
  return (
    <ContentContainerWithRightBar
      key={selectedConversation.value.id}
      isCanvasApp={isCanvasChat}
      fullWidth={isFullWidth}
    >
      <CanvasHolderContext
        // dirty hack to update the canvas app instance above context. did not find a better way
        getCanvasFileUpdateFunctionToTop={updateFileInCanvasAppInstance}
        conversationId={selectedConversation.value.id}
        externalInstances={
          selectedConversation.value.externalInstances as unknown as Record<
            string,
            { blackbox?: string; externalAppId: string }
          >
        }
      >
        <ChatHeader
          selectedConversation={selectedConversation.value}
          fullWidth={isFullWidth}
          isCanvasApp={isCanvasChat}
        />

        {isCanvasChat && <CanvasHolder selectedConversation={selectedConversation.value} />}
        <div
          data-test-id='Chat-container'
          className={cn(styles.chat, styles.chat__chatContainer, {
            [styles.chat__chatContainer_withIframe]: isCanvasChat,
            [styles.chat__chatContainer_fullWidth]: !isCanvasChat && isFullWidth,
          })}
          ref={chatContainerRef}
          onScroll={() => {
            handleScroll();
            handleScrollTop();
          }}
          onWheel={event => {
            if (event.currentTarget.scrollTop === 0) {
              handleScrollTop();
            }
          }}
        >
          <div className={cn(styles.chat__histWrapper, { [styles.chat__histWrapper_canvasChat]: isCanvasChat })}>
            {loadingHistory && (
              <div className={cn(styles.chat__histSpinner)}>
                <Spinner size='sm' />
              </div>
            )}
            {selectedConversation.value.history.map((message, index, history) => {
              const previousMessage = index === 0 ? null : history[index - 1];
              const nextMessage = index < history.length - 1 ? history[index + 1] : undefined;
              return (
                <ChatMessage
                  key={message.id}
                  isLastMessage={index >= history.length - 1}
                  isMessageStreaming={selectedConversation.value?.messageIsStreaming ?? false}
                  messageContent={message.content}
                  messageCreatedAt={message.createdAt}
                  entities={message.entities}
                  messageId={message.id ?? `${index}`}
                  messageType={message.type}
                  previousMessageId={previousMessage?.id}
                  previousMessageType={previousMessage?.type}
                  prevMessageCreatedAt={previousMessage?.createdAt}
                  selectedConversationId={selectedConversation.value!.id}
                  selectedConversationTemplate={selectedConversation.value!.config.template}
                  selectedConversationActions={selectedConversation.value!.actions}
                  sendMessage={handleSend}
                  sendMessageById={handleSendById}
                  dataTestId={`Chat.Message.${index}`}
                  tokensSpent={message.meta?.tokensSpent}
                  nextMessage={nextMessage}
                />
              );
            })}
            {requestWasStopped.value && (
              <ChatMessage
                isLastMessage={true}
                isMessageStreaming={selectedConversation.value?.messageIsStreaming ?? false}
                messageContent={[
                  {
                    type: 'text',
                    text: t('chatCancelReqText'),
                  },
                ]}
                messageCreatedAt={Date.now()}
                messageId={`${selectedConversation.value!.history.length}`}
                messageType='system'
                selectedConversationId={selectedConversation.value!.id}
                selectedConversationTemplate={selectedConversation.value!.config.template}
              />
            )}
            {showLoader && <ChatLoader selectedConversationTemplate={selectedConversation.value!.config.template} />}
            {isError && (
              <div className={cn(styles.chat__message, styles.chat__bannerContainer)}>
                <Banner withIcon BannerText={() => <p>{t('Chat:Template:Error')}</p>} type='warning' iconSize='lg' />
              </div>
            )}
          </div>
          {!isError && (
            <ChatInput
              selectedConversation={selectedConversation.value}
              textareaRef={textareaRef}
              onSend={handleSend}
              onCancelSend={handleCancelSend}
              onScrollDownClick={handleScrollDown}
              showScrollDownButton={showScrollDownButton}
              requestWasStopped={requestWasStopped.value}
            />
          )}
        </div>
        <AgentSettings
          hidden={onlyParamIsFile || !hasAgentSettings || isError}
          selectedConversation={selectedConversation.value}
        />
        {isFileAnalysisChat && onlyParamIsFile && !isMobile() && (
          <FileSettings document={selectedConversation.value.app.params?.['document']} />
        )}
      </CanvasHolderContext>
    </ContentContainerWithRightBar>
  );
});
Chat.displayName = 'Chat';
