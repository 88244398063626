import { FC, memo, useCallback, useEffect, useMemo } from 'react';

import { findConversationIndex } from '../../../models/conversations';
import { setConversationsValue } from '../../../models/conversations/signals';
import useApiService from '../../../services/useApiService';
import { ToolCallMessagePart } from '../../../types/chat';
import { isDev } from '../../../utils/app/common';
import { useCanvasHolder } from '../CanvasHolderContext/CanvasHolderContext';

export const ToolCallMessage: FC<{ content: ToolCallMessagePart['toolCalls']; selectedConversationId: string }> = memo(
  ({ content, selectedConversationId }) => {
    const { createExternalInstance } = useApiService();
    const { sendToolResponseMessageToChat } = useCanvasHolder();

    const showUiCalls = useMemo(
      () => content.filter(contentItem => contentItem.function.name === 'show_ui'),
      [content]
    );
    const sendFileToIframeMsgs = useMemo(
      () => content.filter(contentItem => contentItem.function.name === 'setFile'),
      [content]
    );

    const { setCanvasInstance, setIsCanvasChat, toolCalls, setFileInIframe } = useCanvasHolder();

    const showUi = useCallback(async () => {
      if (!showUiCalls.length) return;
      setIsCanvasChat(true, selectedConversationId);

      const results: { name: string; toolCallId: string; response: string }[] = [];

      for (const showUiCall of showUiCalls) {
        try {
          const parsedArgs = JSON.parse(showUiCall.function.arguments);
          const instanceId = await createExternalInstance(selectedConversationId, parsedArgs.externalAppId);
          const result = await new Promise<{ name: string; toolCallId: string; response: string }>(
            (resolve, reject) => {
              setCanvasInstance(instanceId, parsedArgs.externalAppId, {
                showUiData: {
                  onLoadCallback: () =>
                    resolve({
                      name: showUiCall.function.name,
                      toolCallId: showUiCall.id!,
                      response: 'ui is successfully shown',
                    }),
                  fileUrl: parsedArgs.fileUrl
                    ? isDev()
                      ? window.location.origin + new URL(parsedArgs.fileUrl).pathname
                      : parsedArgs.fileUrl
                    : parsedArgs.fileUrl,
                },
              });
            }
          );
          results.push(result);
        } catch (e) {
          results.push({
            name: showUiCall.function.name,
            toolCallId: showUiCall.id!,
            response: 'ui is not shown',
          });
        }
      }
      sendToolResponseMessageToChat(results);
    }, [
      createExternalInstance,
      selectedConversationId,
      sendToolResponseMessageToChat,
      setCanvasInstance,
      setIsCanvasChat,
      showUiCalls,
    ]);

    const setFileInIframeHandler = useCallback(async () => {
      if (!sendFileToIframeMsgs.length) return;
      for (let sendFileToIframeMsg of sendFileToIframeMsgs) {
        try {
          const parsedArgs = JSON.parse(sendFileToIframeMsg.function.arguments);
          const { uri, type, name } = JSON.parse(sendFileToIframeMsg.function.arguments);
          await setFileInIframe({ uri, type, name }, sendFileToIframeMsg, parsedArgs.externalInstanceId);
        } catch (e) {}
      }
    }, [sendFileToIframeMsgs, setFileInIframe]);
    //force set status to "building", to avoid user write in chat before iframe response
    const setConversationStatusBuilding = useCallback(() => {
      setConversationsValue(storeConversations => {
        const conversationIndex = findConversationIndex(selectedConversationId);
        if (conversationIndex > -1) {
          storeConversations[conversationIndex].status = 'BUILDING';
        }
      });
    }, [selectedConversationId]);

    useEffect(() => {
      setConversationStatusBuilding();
      if (showUiCalls.length) showUi();
      if (sendFileToIframeMsgs.length) setFileInIframeHandler();
      const callsWithoutSpecial = content.filter(
        contentItem => !['show_ui', 'setFile'].includes(contentItem.function.name)
      );
      if (callsWithoutSpecial.length > 0) {
        toolCalls(callsWithoutSpecial);
      }
    }, [
      content,
      sendFileToIframeMsgs.length,
      setConversationStatusBuilding,
      setFileInIframeHandler,
      showUi,
      showUiCalls.length,
      toolCalls,
    ]);

    return null;
  }
);
