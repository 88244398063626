export const commonLocalization = {
  signIn: {
    eng: 'Sign in',
    ru: 'Войти',
  },
  register: {
    eng: 'Sign up',
    ru: 'Зарегистрироваться',
  },
  defaultBrand: {
    eng: 'Tovie AI',
    ru: 'Just AI',
  },
  defaultProductName: {
    eng: 'Tai Copilot',
    ru: 'Jay Copilot',
  },
  dataGuardProductName: {
    eng: 'Jay Guard',
    ru: 'Jay Guard',
  },
  welcome: {
    eng: 'Application catalog',
    ru: 'Каталог приложений',
  },
  welcomeAlfa: {
    eng: 'Welcome to Smarty',
    ru: 'Добро пожаловать в Smarty',
  },
  welcomeInfo: {
    eng: 'Sign in to use #{config.productName}.',
    ru: 'Авторизуйтесь, чтобы использовать #{config.productName}.',
  },
  allTemplates: {
    eng: 'Applications',
    ru: 'Приложения',
  },
  myTemplates: {
    eng: 'Created',
    ru: 'Избранное',
  },
  noParams: {
    eng: 'To continue, click “Create” and then ask your question.',
    ru: 'Чтобы продолжить работу с приложением, нажмите «Создать», а затем отправьте свой запрос.',
  },
  logoutMsg: {
    eng: 'Are you sure you want to sign out? Your conversations will be saved in your profile.',
    ru: 'Вы уверены что хотите выйти? Ваши диалоги сохранятся в вашем профиле.',
  },
  logout: {
    eng: 'Log out',
    ru: 'Выход',
  },
  logoutTitle: {
    eng: 'Sign out',
    ru: 'Выйти из профиля',
  },
  logoutVerb: {
    eng: 'Sign out',
    ru: 'Выйти',
  },
  cancel: {
    eng: 'Cancel',
    ru: 'Отмена',
  },
  close: {
    eng: 'Close',
    ru: 'Закрыть',
  },
  theme: {
    eng: 'Theme',
    ru: 'Тема',
  },
  save: {
    eng: 'Save',
    ru: 'Сохранить',
  },
  'Dark mode': {
    eng: 'Dark mode',
    ru: 'Темная тема',
  },
  'Light mode': {
    eng: 'Light mode',
    ru: 'Светлая тема',
  },
  'This field is required': {
    eng: 'Required field',
    ru: 'Это поле обязательно для заполнения',
  },
  Update: {
    eng: 'Update',
    ru: 'Обновить',
  },
  AddToFavourite: {
    eng: 'Add to Favorites',
    ru: 'Добавить в Избранное',
  },
  Create: {
    eng: 'Create',
    ru: 'Создать',
  },
  'StringArray:AddField': {
    eng: 'Add more',
    ru: 'Добавить еще',
  },
  assistantJay: {
    eng: 'Assistant Tai',
    ru: 'Ассистент Jay',
  },
  'assistantJay:old': {
    eng: 'Assistant Tai: archived',
    ru: 'Ассистент Jay: архив',
  },
  prompt_assistant: {
    eng: 'Hello! I am assistant Tai, I will help you with AI. Where would you like to start?',
    ru: 'Привет! Я ассистент Jay, помогу вам в работе с нейросетями. С чего начнем?',
  },
  prompt_toolAssistant: {
    eng: 'Hello! I am assistant Tai, I will help you with AI. Where would you like to start?',
    ru: 'Привет! Я ассистент Jay, помогу вам в работе с нейросетями. С чего начнем?',
  },
  NoFavourite: {
    eng: 'Did you like the applet you created? Add it to the favorites',
    ru: 'Понравилось созданное приложение? Добавьте его в избранное',
  },
  Confirm: {
    eng: 'Confirm',
    ru: 'Подтвердить',
  },
  'Confirm:Template:Update': {
    eng: 'Yes, update',
    ru: 'Да, обновить',
  },
  Back: {
    eng: 'Back',
    ru: 'Вернуться',
  },
  restartOnboarding: {
    eng: 'Onboarding',
    ru: 'Обучение Smarty',
  },
  support: {
    eng: 'Support',
    ru: 'Техническая поддержка',
  },
  supportEmail: {
    eng: 'support@just-ai.com',
    ru: 'support@just-ai.com',
  },
  'supportEmail:tovie': {
    eng: 'contact@tovie.ai',
    ru: 'contact@tovie.ai',
  },
  supportMessage: {
    eng: 'If you have any questions about #{config.productName}, please contact our technical support: #{config.supportMail}. We will process your request on weekdays from 10 to 18 GMT+3.',
    ru: 'Если у вас возникли вопросы по работе #{config.productName}, оставьте обращение в техническую поддержку: #{config.supportMail}. Мы рассмотрим ваше обращение в рабочие дни с 10 до 18 МСК.',
  },
  Search: {
    eng: 'Search',
    ru: 'Поиск',
  },
  SearchApps: {
    eng: 'Application search',
    ru: 'Поиск по всем приложениям',
  },
  'page403:Title': {
    ru: 'К сожалению, у вас нет доступа к сервису',
  },
  'page403:Body:x5': {
    ru: 'Для предоставления доступа необходимо завести заявку на access.x5.ru',
  },
  clear: {
    eng: 'Clear',
    ru: 'Очистить',
  },
  license: {
    eng: 'Terms of use',
    ru: 'Пользовательское соглашение',
  },
  docsAndLearning: {
    eng: '',
    ru: 'Документация и обучение',
  },
  apiKeys: {
    eng: 'API keys',
    ru: 'API-ключи',
  },
  generate: {
    eng: 'Generate',
    ru: 'Сгенерировать',
  },
  'Demo:Panel:title': {
    eng: 'Welcome to the #{config.productName} demo mode!',
    ru: 'Добро пожаловать в демонстрационный режим #{config.productName}!',
  },
  'Demo:Modal:name': {
    eng: '🚀 Do you want to give #{config.productName} a try?',
    ru: '🚀 Хотите попробовать #{config.productName}?',
  },
  'Demo:Modal:text': {
    eng: 'Sign up and try all the features of #{config.productName} for free! We credit welcome tokens to each new user. With them, you can create about 20 images and send approximately 70 requests to AI.',
    ru: 'Зарегистрируйтесь и попробуйте все возможности #{config.productName} бесплатно! Каждому новому пользователю мы начисляем приветственные токены. На них вы сможете создать около 20 изображений и отправить примерно 70 запросов нейросетям.',
  },
  'Demo:Modal:submit': {
    eng: 'Sign up',
    ru: 'Да, зарегистрироваться',
  },
  community: {
    eng: 'Community',
    ru: 'Сообщество',
  },
  jayCommunity: {
    eng: 'Tai • Community',
    ru: 'Джей • Комьюнити',
  },
  jayCommunitySub: {
    eng: 'User community',
    ru: 'Сообщество пользователей',
  },
  jayCopilot: {
    eng: 'Tai Copilot',
    ru: 'Jay Copilot',
  },
  jayCopilotSub: {
    eng: 'Channel about AI world events',
    ru: 'Канал про события в мире ИИ',
  },
  jayGuard: {
    eng: 'Jay Guard',
    ru: 'Jay Guard',
  },
  'jayGuard:status:on': {
    eng: 'On',
    ru: 'Работает',
  },
  'jayGuard:status:off': {
    eng: 'Off',
    ru: 'Отключен',
  },
  'jayGuard:status:error': {
    eng: 'Operation error',
    ru: 'Ошибка в работе',
  },
  'jayGuard:select:label': {
    eng: 'Select an access key',
    ru: 'Выберите ключ доступа',
  },
  'jayGuard:select:ph': {
    eng: 'No key selected',
    ru: 'Ключ не выбран',
  },
  'jayGuard:switch:email:label': {
    eng: 'Share user emails',
    ru: 'Передавать email пользователей',
  },
  'jayGuard:switch:email:hint': {
    eng: 'User emails will be displayed in Jay Guard audit events',
    ru: 'В событиях аудита Jay Guard будут отображаться email пользователей',
  },
  enable: {
    eng: 'Enable',
    ru: 'Включить',
  },
  disable: {
    eng: 'Disable',
    ru: 'Отключить',
  },
  'jayGuard:modal:working': {
    eng: '{productName} is on and protects your data',
    ru: '{productName} работает и защищает данные',
  },
  'jayGuard:modal:error': {
    eng: 'Failed to enable {productName}, please check the key in the {productName} platform.',
    ru: 'Не удалось включить {productName}, проверьте ключ в сервисе {productName}.',
  },
  'jayGuard:modal:errorPanel': {
    eng: '{productName} is not protecting your data. Please update the access key.',
    ru: '{productName} не защищает данные. Обновите ключ доступа.',
  },
  'jayGuard:modal:noKey': {
    eng: 'Select a key',
    ru: 'Выберите ключ',
  },
  'jayGuard:Panel:title': {
    eng: '{productName} is not protecting your data. Please update the access key in the settings.',
    ru: '{productName} не защищает данные. Обновите ключ доступа в настройках.',
  },
  'jayGuard:Panel:titleNoBtn': {
    eng: 'You are temporarily unable to send requests to #{config.productName}. Please contact your administrator to restore access.',
    ru: 'Вы временно не можете отправлять запросы в #{config.productName}. Обратитесь к администратору, чтобы восстановить доступ.',
  },
  'jayGuard:Panel:btn': {
    eng: 'Go to the {productName} settings',
    ru: 'В настройки {productName}',
  },
  corporateBtn: {
    eng: '',
    ru: '#{config.productName} для бизнеса',
  },
  'Alert:ChatMessageDeletionError': {
    eng: 'An error occurred while deleting the message.',
    ru: 'При удалении сообщения произошла ошибка.',
  },
  help: {
    eng: 'Help',
    ru: 'Помощь',
  },
  company: {
    eng: 'Company',
    ru: 'Компания',
  },
  myProfile: {
    eng: 'My profile',
    ru: 'Мой профиль',
  },
  tokensSpent: {
    eng: '',
    ru: 'Расход токенов',
  },
  'Sidebar:SelectAcount': {
    eng: 'Account: {currentAccountName}',
    ru: 'Аккаунт: {currentAccountName}',
  },
  User: {
    eng: 'User',
    ru: 'Пользователь',
  },
  LimitsMore: {
    eng: 'Learn more about limits',
    ru: 'Подробнее о лимитах',
  },
};
