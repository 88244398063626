import React, {
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button, IconButton, Spinner, Tooltip, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import Attachments from './Attachments';
import { MAX_FILE_SIZE_CHAT } from './consts';
import { NoMoreThen3SyncRequestsTextInform } from './NoMoreThen3SyncRequestsTextInform';
import {
  appHasUnsavedChanges,
  settingsModalIsOpenedFromChat,
  showModalFromChatInput,
} from './signals/ChatUpdateSignal';
import styles from './style.module.scss';
import { appHasSystemFeature } from '../../api/cc.api';
import { isDemoMode } from '../../api/client';
import AppContext from '../../contexts/appContext';
import { showSignupModal } from '../../hooks/showSignup';
import useAudioRecorder from '../../hooks/useAudioRecorder';
import { isX5 } from '../../isX5';
import { isRequestsLimitExceeded } from '../../models/conversations';
import { currentUser } from '../../models/currentUser';
import { templates } from '../../models/templates';
import { guideTourEvent$ } from '../../modules/GuideTourStepper/guideTourEvents';
import { Conversation, FileMessagePart, Message } from '../../types/chat';
import { isMobile } from '../../utils/app/common';
import { createDefaultMsg } from '../../utils/app/conversation';

const MAX_FILES = 1;

interface Props {
  selectedConversation: Conversation;
  onSend: (message: Message, audio?: Blob) => void;
  onScrollDownClick: () => void;
  onCancelSend: () => void;
  textareaRef?: MutableRefObject<HTMLTextAreaElement | null>;
  showScrollDownButton: boolean;
  requestWasStopped?: boolean;
}

export const ChatInput = ({
  onSend,
  onScrollDownClick,
  textareaRef,
  showScrollDownButton,
  onCancelSend,
  selectedConversation,
  requestWasStopped,
}: Props) => {
  const { t } = useTranslation();
  const { templatesMap } = templates.value;
  const {
    state: { lightMode },
    addAlert,
  } = useContext(AppContext);

  const [content, setContent] = useState('');
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const [files, setFiles] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const { recorderState, cancelRecording, saveRecording, startRecording, localAudio, setMicDisabled } =
    useAudioRecorder();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isDemoMode.value) return;
    const value = e.target.value;

    setContent(value);
  };

  const handleUpdateSettingsClick = () => {
    showModalFromChatInput.value = true;
    settingsModalIsOpenedFromChat.value = true;
  };

  const handleSend = () => {
    if (selectedConversation.messageIsStreaming || isRequestsLimitExceeded.value) {
      return;
    }
    guideTourEvent$.next(`ChatInput:send:${selectedConversation?.config.template || 'Empty'}`);

    if (!content && !files.length && !recorderState.initRecording) {
      alert(t('Please enter a message'));
      return;
    }

    if (appHasUnsavedChanges.value) {
      handleUpdateSettingsClick();
      return;
    }

    if (recorderState.initRecording) {
      saveRecording();
      return;
      //дальнейшая отправка аудио осуществляется в эффекте на строке 182
    }

    onSend(
      createDefaultMsg(
        [{ type: 'text', text: content }, ...files.map(file => ({ type: 'file', file }) as FileMessagePart)],
        selectedConversation.id,
        'request'
      )
    );
    setContent('');
    setFiles([]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    if (window.innerWidth < 640 && textareaRef && textareaRef.current) {
      textareaRef.current.blur();
    }
  };

  const handleStartRecording = () => {
    if (!recorderState.initRecording) {
      startRecording().catch(error => {
        if (isMobile()) {
          setMicDisabled(true);
        }
      });
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length && event.target.files.length > MAX_FILES) {
      if (inputRef.current) {
        inputRef.current.files = null;
        inputRef.current.value = '';
      }
      return alert(t('maxFilesInfo', MAX_FILES));
    }
    const filesArr = Array.from(event.target.files || []);
    if (filesArr.some(file => file.size / 1024 / 1024 > MAX_FILE_SIZE_CHAT)) {
      if (inputRef.current) {
        inputRef.current.files = null;
        inputRef.current.value = '';
      }
      return addAlert(t('fileSizeError__param', { size: MAX_FILE_SIZE_CHAT }), 'info');
    }
    setFiles(filesArr);
  };

  const handleFileRemove = (file: File) => {
    setFiles(files => files.filter(localFile => file.name !== localFile.name));
    if (inputRef.current) {
      inputRef.current.files = null;
      inputRef.current.value = '';
    }
  };

  const handleStopConversation = () => {
    onCancelSend();
  };

  const hideAttachButton = useMemo(
    () => templatesMap[selectedConversation.app.template]?.clientFeatures?.fileAttachment === false,
    [selectedConversation, templatesMap]
  );

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      requestAnimationFrame(() => {
        if (textareaRef.current) textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
      });
    }
  }, [content, textareaRef]);

  useEffect(() => {
    //аудио обрабатывается асинхронно в хуке и приходит в localAudio
    if (localAudio.current && !selectedConversation.messageIsStreaming) {
      onSend(
        createDefaultMsg(
          [{ type: 'audio', url: URL.createObjectURL(localAudio.current) }],
          selectedConversation.id,
          'request'
        ),
        localAudio.current
      );
      cancelRecording();
    }
  }, [cancelRecording, localAudio, onSend, selectedConversation.id, selectedConversation.messageIsStreaming]);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !isTyping && !isMobile() && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const inputDisabled = currentUser.value?.accountLimit?.tokenLimit === 0 || recorderState.initRecording;

  const showDefaultSendButton = Boolean(
    content.length ||
      files.length ||
      !appHasSystemFeature('voice_messages') ||
      localAudio.current ||
      recorderState.initRecording ||
      recorderState.mediaRecorder?.state === 'paused'
  );

  return (
    <div
      className={styles.chat__newMessageBlock}
      onClick={() => {
        if (appHasUnsavedChanges.value) handleUpdateSettingsClick();
      }}
    >
      <div className={cn('flex flex-col gap-3 relative', styles.chat__newMessageBlockWrapper)}>
        <div className={styles.chat__additionalActionContainer}>
          {showScrollDownButton && (
            <IconButton
              className={cn(styles.chat__inputBtnScrollDown)}
              onClick={onScrollDownClick}
              name='farChevronDown'
              flat
              outline={true}
              color='none'
            />
          )}
          {isRequestsLimitExceeded.value ? <NoMoreThen3SyncRequestsTextInform /> : null}
          {isMobile() && recorderState.micDisabled ? (
            <div className={styles.chatTooltip}>{t('ChatInput:MicDisabledTooltip:mobile')}</div>
          ) : null}
          {selectedConversation.messageIsStreaming && !requestWasStopped && selectedConversation.history.length > 0 && (
            <Button
              iconLeft='farBan'
              className={cn(styles.chat__btnStop, 'bg-hover-color-gray-200')}
              onClick={handleStopConversation}
              outline={true}
              color='secondary'
              size={isMobile() ? 'md' : 'sm'}
              data-test-id='Chat.CancelSend'
            >
              {t('ChatInput:StopGenerating')}
            </Button>
          )}
        </div>
        <div
          data-test-id={`NewMessageInputWrapper:${selectedConversation.app.template || 'Empty'}`}
          className={cn('flex w-full flex-grow flex-col', lightMode, styles.chat__newMessageInputWrapper)}
        >
          {files?.length > 0 && <Attachments files={files} handleFileRemove={handleFileRemove} />}
          <textarea
            ref={textareaRef}
            data-test-id='NewMessageInput'
            className={cn('w-full', lightMode, styles.chat__newMessageTextarea)}
            style={{
              resize: 'none',
              bottom: `${textareaRef?.current?.scrollHeight}px`,
              maxHeight: '200px',
              overflowY: `scroll`,
            }}
            placeholder={t('typeMessage')}
            value={content}
            onCompositionStart={() => setIsTyping(true)}
            onCompositionEnd={() => setIsTyping(false)}
            onClick={() => isDemoMode.value && showSignupModal(true)}
            disabled={inputDisabled}
            rows={1}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={() => guideTourEvent$.next(`ChatInput:focus:${selectedConversation?.app.template || 'Empty'}`)}
          />

          <div className={cn(styles.chat__inputBtnWrapper)} style={{ height: '38px' }}>
            {recorderState.initRecording && (
              <>
                {recorderState.recPaused && <p className='text-secondary'>{t('ChatInput:RecordPaused')}</p>}
                <p>
                  {recorderState.recordingMinutes}:
                  {recorderState.recordingSeconds < 10
                    ? `0${recorderState.recordingSeconds}`
                    : recorderState.recordingSeconds}
                </p>
                <div
                  className={cn(styles.chat__recordingIndicator, {
                    'animate-pulse': !recorderState.recPaused,
                    [styles['chat__recordingIndicator--paused']]: recorderState.recPaused,
                  })}
                />
                <IconButton flat square name='farTrashAlt' onClick={cancelRecording} />
              </>
            )}

            {!isX5 && !hideAttachButton && !isDemoMode.value && !recorderState.initRecording && (
              <>
                <input type='file' className='display-none' ref={inputRef} onChange={handleFileUpload} />
                <IconButton
                  data-test-id='NewMessageInput:file_btn'
                  square
                  size={isMobile() ? 'lg' : 'md'}
                  onClick={() => inputRef.current?.click()}
                  name='farPaperclip'
                  flat
                  color='secondary'
                />
              </>
            )}
            {selectedConversation.messageIsStreaming ? (
              <div>
                <Spinner size='md' inline style={{ margin: '0 12px' }} />
              </div>
            ) : showDefaultSendButton ? (
              <IconButton
                square
                data-test-id='NewMessageInput:file_send'
                onClick={handleSend}
                name='fasPaperPlane'
                size={isMobile() ? 'lg' : 'md'}
                flat
                color='primary'
                disabled={isRequestsLimitExceeded.value}
              />
            ) : (
              <>
                <IconButton
                  square
                  data-test-id='NewMessageInput:record_voice'
                  disabled={recorderState.micDisabled}
                  onClick={handleStartRecording}
                  name='faMicrophone'
                  id='recordAudioBtn'
                  size={isMobile() ? 'lg' : 'md'}
                  flat
                  color='secondary'
                />
                {!isMobile() && (
                  <Tooltip target='recordAudioBtn'>
                    {t(recorderState.micDisabled ? 'ChatInput:MicDisabledTooltip' : 'ChatInput:RecordTooltip')}
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
