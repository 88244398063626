import React, { FC, memo, useEffect } from 'react';

import { Spinner } from '@just-ai/just-ui';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router';

import { useAppContext } from '../../contexts/appContext';
import localize from '../../localization';
import { useConversations } from '../../models/conversations/hooks';
import { conversationsLoaded } from '../../models/conversations/signals';
import { externalApps, externalAppsLoaded } from '../../models/externalApps/signals';
import { useFavorite } from '../../models/favourite/favourite.api';
import { useTemplates, templatesLoaded } from '../../models/templates';
import { goToMain, routes } from '../../routes';

export const LoadingContainer: FC = memo(({ children }) => {
  const {
    state: { locale },
  } = useAppContext();

  const { errorUserChats } = useConversations(true);
  const { loadedTemplates, errorTemplates } = useTemplates(locale, true);
  const { errorFavourite } = useFavorite(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (loadedTemplates !== null) {
      // TODO Remove this hack when implementing the new conversations API.
      const promptTranslations = Object.entries(loadedTemplates.templatesMap).reduce(
        (acc, [templateName, template]) => {
          const promptKey = `prompt_${templateName}`;
          const promptValue = template.info.prompt || '';
          return { ...acc, [promptKey]: { [locale]: promptValue } };
        },
        {}
      );
      localize.addTranslations(promptTranslations);
    }

    if (!errorUserChats && !errorFavourite && pathname.includes(routes.login)) {
      goToMain();
    }
  }, [errorUserChats, errorTemplates, pathname, loadedTemplates, locale, errorFavourite]);

  if (!conversationsLoaded.value || !templatesLoaded.value || !externalAppsLoaded.value)
    return createPortal(<Spinner backgroundColor='white' />, document.body);

  return <>{children}</>;
});
