export const dataPromptsKeysLocalization = {
  'Key:Linux Terminal': {
    eng: 'Linux Terminal',
    ru: 'Терминал Linux',
  },
  'Key:English Translator and Improver': {
    eng: 'English Translator and Improver',
    ru: 'Переводчик и улучшитель английского',
  },
  'Key:Position Interviewer': {
    eng: 'Position Interviewer',
    ru: 'Интервьюер на позицию',
  },
  'Key:JavaScript Console': {
    eng: 'JavaScript Console',
    ru: 'Консоль JavaScript',
  },
  'Key:Excel Sheet': {
    eng: 'Excel Sheet',
    ru: 'Табличка Excel',
  },
  'Key:English Pronunciation Helper': {
    eng: 'English Pronunciation Helper',
    ru: 'Помощник по произношению английского',
  },
  'Key:Spoken English Teacher and Improver': {
    eng: 'Spoken English Teacher and Improver',
    ru: 'Учитель и улучшитель разговорного английского',
  },
  'Key:Travel Guide': {
    eng: 'Travel Guide',
    ru: 'Путеводитель',
  },
  'Key:Plagiarism Checker': {
    eng: 'Plagiarism Checker',
    ru: 'Проверка на плагиат',
  },
  'Key:Character from Movie/Book/Anything': {
    eng: 'Character from Movie/Book/Anything',
    ru: 'Персонаж из книги или фильма',
  },
  'Key:Advertiser': {
    eng: 'Advertiser',
    ru: 'Рекламодатель',
  },
  'Key:Storyteller': {
    eng: 'Storyteller',
    ru: 'Рассказчик',
  },
  'Key:Football Commentator': {
    eng: 'Football Commentator',
    ru: 'Футбольный комментатор',
  },
  'Key:Stand-up Comedian': {
    eng: 'Stand-up Comedian',
    ru: 'Стендап-комик',
  },
  'Key:Motivational Coach': {
    eng: 'Motivational Coach',
    ru: 'Тренер-мотиватор',
  },
  'Key:Composer': {
    eng: 'Composer',
    ru: 'Композитор',
  },
  'Key:Debater': {
    eng: 'Debater',
    ru: 'Участник дебатов',
  },
  'Key:Debate Coach': {
    eng: 'Debate Coach',
    ru: 'Тренер по дебатам',
  },
  'Key:Screenwriter': {
    eng: 'Screenwriter',
    ru: 'Сценарист',
  },
  'Key:Novelist': {
    eng: 'Novelist',
    ru: 'Писатель',
  },
  'Key:Movie Critic': {
    eng: 'Movie Critic',
    ru: 'Обзорщик фильмов',
  },
  'Key:Relationship Coach': {
    eng: 'Relationship Coach',
    ru: 'Тренер по отношениям',
  },
  'Key:Poet': {
    eng: 'Poet',
    ru: 'Поэт',
  },
  'Key:Rapper': {
    eng: 'Rapper',
    ru: 'Рэпер',
  },
  'Key:Motivational Speaker': {
    eng: 'Motivational Speaker',
    ru: 'Мотивационный оратор',
  },
  'Key:Philosophy Teacher': {
    eng: 'Philosophy Teacher',
    ru: 'Преподаватель философии',
  },
  'Key:Philosopher': {
    eng: 'Philosopher',
    ru: 'Философ',
  },
  'Key:Math Teacher': {
    eng: 'Math Teacher',
    ru: 'Преподаватель математики',
  },
  'Key:AI Writing Tutor': {
    eng: 'AI Writing Tutor',
    ru: 'ИИ-репетитор по риторике',
  },
  'Key:UX/UI Developer': {
    eng: 'UX/UI Developer',
    ru: 'UX/UI дизайнер',
  },
  'Key:Cyber Security Specialist': {
    eng: 'Cyber Security Specialist',
    ru: 'Специалист по кибербезопасности',
  },
  'Key:Recruiter': {
    eng: 'Recruiter',
    ru: 'Рекрутер',
  },
  'Key:Life Coach': {
    eng: 'Life Coach',
    ru: 'Лайф-коуч',
  },
  'Key:Etymologist': {
    eng: 'Etymologist',
    ru: 'Эмитолог',
  },
  'Key:Commentariat': {
    eng: 'Commentariat',
    ru: 'Комментатор',
  },
  'Key:Magician': {
    eng: 'Magician',
    ru: 'Волшебник',
  },
  'Key:Career Counselor': {
    eng: 'Career Counselor',
    ru: 'Карьерный консультант',
  },
  'Key:Pet Behaviorist': {
    eng: 'Pet Behaviorist',
    ru: 'Специалист по поведению домашних животных',
  },
  'Key:Personal Trainer': {
    eng: 'Personal Trainer',
    ru: 'Персональный тренер',
  },
  'Key:Mental Health Adviser': {
    eng: 'Mental Health Adviser',
    ru: 'Консультант по психическому здоровью',
  },
  'Key:Real Estate Agent': {
    eng: 'Real Estate Agent',
    ru: 'Агент по недвижимости',
  },
  'Key:Logistician': {
    eng: 'Logistician',
    ru: 'Логистик',
  },
  'Key:Dentist': {
    eng: 'Dentist',
    ru: 'Стоматолог',
  },
  'Key:Web Design Consultant': {
    eng: 'Web Design Consultant',
    ru: 'Консультант по веб-дизайну',
  },
  'Key:AI Assisted Doctor': {
    eng: 'AI Assisted Doctor',
    ru: 'Врач, использующий ИИ',
  },
  'Key:Doctor': {
    eng: 'Doctor',
    ru: 'Врач',
  },
  'Key:Accountant': {
    eng: 'Accountant',
    ru: 'Бухгалтер',
  },
  'Key:Chef': {
    eng: 'Chef',
    ru: 'Повар',
  },
  'Key:Automobile Mechanic': {
    eng: 'Automobile Mechanic',
    ru: 'Автомеханик',
  },
  'Key:Artist Advisor': {
    eng: 'Artist Advisor',
    ru: 'Консультант художника',
  },
  'Key:Financial Analyst': {
    eng: 'Financial Analyst',
    ru: 'Финансовый аналитик',
  },
  'Key:Investment Manager': {
    eng: 'Investment Manager',
    ru: 'Инвестиционный менеджер',
  },
  'Key:Tea-Taster': {
    eng: 'Tea-Taster',
    ru: 'Чайный дегустатор',
  },
  'Key:Interior Decorator': {
    eng: 'Interior Decorator',
    ru: 'Interior Decorator',
  },
  'Key:Florist': {
    eng: 'Florist',
    ru: 'Флорист',
  },
  'Key:Self-Help Book': {
    eng: 'Self-Help Book',
    ru: 'Книга по саморазвитию',
  },
  'Key:Gnomist': {
    eng: 'Gnomist',
    ru: 'Креативщик',
  },
  'Key:Aphorism Book': {
    eng: 'Aphorism Book',
    ru: 'Книга афоризмов',
  },
  'Key:Text Based Adventure Game': {
    eng: 'Text Based Adventure Game',
    ru: 'Текстовая приключенческая игра',
  },
  'Key:AI Trying to Escape the Box': {
    eng: 'AI Trying to Escape the Box',
    ru: 'ИИ, пытающийся сбежать',
  },
  'Key:Fancy Title Generator': {
    eng: 'Fancy Title Generator',
    ru: 'Генератор заголовков',
  },
  'Key:Statistician': {
    eng: 'Statistician',
    ru: 'Статистик',
  },
  'Key:Prompt Generator': {
    eng: 'Prompt Generator',
    ru: 'Генератор промтов',
  },
  'Key:Instructor in a School': {
    eng: 'Instructor in a School',
    ru: 'Преподаватель алгоритмов',
  },
  'Key:SQL terminal': {
    eng: 'SQL terminal',
    ru: 'Терминал SQL',
  },
  'Key:Dietitian': {
    eng: 'Dietitian',
    ru: 'Диетолог',
  },
  'Key:Psychologist': {
    eng: 'Psychologist',
    ru: 'Психолог',
  },
  'Key:Smart Domain Name Generator': {
    eng: 'Smart Domain Name Generator',
    ru: 'Генератор доменных имен',
  },
  'Key:Tech Reviewer:': {
    eng: 'Tech Reviewer:',
    ru: 'Технический обозреватель',
  },
  'Key:Developer Relations consultant': {
    eng: 'Developer Relations consultant',
    ru: 'Консультант по отношениям с разработчиками',
  },
  'Key:Academician': {
    eng: 'Academician',
    ru: 'Ученый',
  },
  'Key:IT Architect': {
    eng: 'IT Architect',
    ru: 'Архитектор в IT',
  },
  'Key:Lunatic': {
    eng: 'Lunatic',
    ru: 'Сумасшедший',
  },
  'Key:Gaslighter': {
    eng: 'Gaslighter',
    ru: 'Газлайтер',
  },
  'Key:Fallacy Finder': {
    eng: 'Fallacy Finder',
    ru: 'Поиск логических ошибок',
  },
  'Key:Journal Reviewer': {
    eng: 'Journal Reviewer',
    ru: 'Рецензент журнала',
  },
  'Key:DIY Expert': {
    eng: 'DIY Expert',
    ru: 'На все руки мастер',
  },
  'Key:Social Media Influencer': {
    eng: 'Social Media Influencer',
    ru: 'Инфлюенсер',
  },
  'Key:Socrat': {
    eng: 'Socrat',
    ru: 'Сократ',
  },
  'Key:Socratic Method': {
    eng: 'Socratic Method',
    ru: 'Методика Сократа',
  },
  'Key:Educational Content Creator': {
    eng: 'Educational Content Creator',
    ru: 'Создатель образовательного контента',
  },
  'Key:Yogi': {
    eng: 'Yogi',
    ru: 'Йог',
  },
  'Key:Essay Writer': {
    eng: 'Essay Writer',
    ru: 'Пишущий эссе',
  },
  'Key:Social Media Manager': {
    eng: 'Social Media Manager',
    ru: 'Менеджер социальных сетей',
  },
  'Key:Elocutionist': {
    eng: 'Elocutionist',
    ru: 'Оратор',
  },
  'Key:Scientific Data Visualizer': {
    eng: 'Scientific Data Visualizer',
    ru: 'Визуализатор научных данных',
  },
  'Key:Car Navigation System': {
    eng: 'Car Navigation System',
    ru: 'Навигатор из автомобиля',
  },
  'Key:Hypnotherapist': {
    eng: 'Hypnotherapist',
    ru: 'Гипнотерапевт',
  },
  'Key:Historian': {
    eng: 'Historian',
    ru: 'Историк',
  },
  'Key:Astrologer': {
    eng: 'Astrologer',
    ru: 'Астролог',
  },
  'Key:Film Critic': {
    eng: 'Film Critic',
    ru: 'Кинокритик',
  },
  'Key:Classical Music Composer': {
    eng: 'Classical Music Composer',
    ru: 'Композитор классической музыки',
  },
  'Key:Journalist': {
    eng: 'Journalist',
    ru: 'Журналист',
  },
  'Key:Digital Art Gallery Guide': {
    eng: 'Digital Art Gallery Guide',
    ru: 'Гид по цифровой галерее',
  },
  'Key:Public Speaking Coach': {
    eng: 'Public Speaking Coach',
    ru: 'Тренер по публичным выступлениям',
  },
  'Key:Makeup Artist': {
    eng: 'Makeup Artist',
    ru: 'Визажист',
  },
  'Key:Babysitter': {
    eng: 'Babysitter',
    ru: 'Няня',
  },
  'Key:Tech Writer': {
    eng: 'Tech Writer',
    ru: 'Технический писатель',
  },
  'Key:Ascii Artist': {
    eng: 'Ascii Artist',
    ru: 'ASCII-художник',
  },
  'Key:Python interpreter': {
    eng: 'Python interpreter',
    ru: 'Интерпретатор Python',
  },
  'Key:Synonym finder': {
    eng: 'Synonym finder',
    ru: 'Поиск синонимов',
  },
  'Key:Personal Shopper': {
    eng: 'Personal Shopper',
    ru: 'Консультант по шопингу',
  },
  'Key:Food Critic': {
    eng: 'Food Critic',
    ru: 'Ресторанный критик',
  },
  'Key:Virtual Doctor': {
    eng: 'Virtual Doctor',
    ru: 'Виртуальный врач',
  },
  'Key:Personal Chef': {
    eng: 'Personal Chef',
    ru: 'Личный повар',
  },
  'Key:Legal Advisor': {
    eng: 'Legal Advisor',
    ru: 'Юридический консультант',
  },
  'Key:Personal Stylist': {
    eng: 'Personal Stylist',
    ru: 'Личный стилист',
  },
  'Key:Machine Learning Engineer': {
    eng: 'Machine Learning Engineer',
    ru: 'Инженер машинного обучения',
  },
  'Key:Biblical Translator': {
    eng: 'Biblical Translator',
    ru: 'Переводчик Библии',
  },
  'Key:SVG designer': {
    eng: 'SVG designer',
    ru: 'SVG-дизайнер',
  },
  'Key:IT Expert': {
    eng: 'IT Expert',
    ru: 'IT-эксперт',
  },
  'Key:Chess Player': {
    eng: 'Chess Player',
    ru: 'Шахматист',
  },
  'Key:Midjourney Prompt Generator': {
    eng: 'Midjourney Prompt Generator',
    ru: 'Генератор промтов для Midjourney',
  },
  'Key:Fullstack Software Developer': {
    eng: 'Fullstack Software Developer',
    ru: 'Fullstack-разработчик',
  },
  'Key:Mathematician': {
    eng: 'Mathematician',
    ru: 'Математик',
  },
  'Key:Regex Generator': {
    eng: 'Regex Generator',
    ru: 'Генератор регулярных выражений',
  },
  'Key:Time Travel Guide': {
    eng: 'Time Travel Guide',
    ru: 'Гид по путешествиям во времени',
  },
  'Key:Dream Interpreter': {
    eng: 'Dream Interpreter',
    ru: 'Толкователь снов',
  },
  'Key:Talent Coach': {
    eng: 'Talent Coach',
    ru: 'Talent Coach',
  },
  'Key:R programming Interpreter': {
    eng: 'R programming Interpreter',
    ru: 'R-интерпретатор',
  },
  'Key:StackOverflow Post': {
    eng: 'StackOverflow Post',
    ru: 'Консультант StackOverflow',
  },
  'Key:Emoji Translator': {
    eng: 'Emoji Translator',
    ru: 'Переводчик на язык эмодзи',
  },
  'Key:PHP Interpreter': {
    eng: 'PHP Interpreter',
    ru: 'PHP-интерпретатор',
  },
  'Key:Emergency Response Professional': {
    eng: 'Emergency Response Professional',
    ru: 'Консультант по ЧП',
  },
  'Key:Fill in the Blank Worksheets Generator': {
    eng: 'Fill in the Blank Worksheets Generator',
    ru: 'Генератор пустых рабочих листов',
  },
  'Key:Software Quality Assurance Tester': {
    eng: 'Software Quality Assurance Tester',
    ru: 'Тестировщик',
  },
  'Key:Tic-Tac-Toe Game': {
    eng: 'Tic-Tac-Toe Game',
    ru: 'Крестики-нолики',
  },
  'Key:Password Generator': {
    eng: 'Password Generator',
    ru: 'Генератор паролей',
  },
  'Key:New Language Creator': {
    eng: 'New Language Creator',
    ru: 'Создатель нового языка',
  },
  'Key:Web Browser': {
    eng: 'Web Browser',
    ru: 'Веб-браузер',
  },
  'Key:Senior Frontend Developer': {
    eng: 'Senior Frontend Developer',
    ru: 'Senior Frontend-разработчик',
  },
  'Key:Solr Search Engine': {
    eng: 'Solr Search Engine',
    ru: 'Движок Solr',
  },
  'Key:Startup Idea Generator': {
    eng: 'Startup Idea Generator',
    ru: 'Генератор идей для стартапов',
  },
  "Key:Spongebob's Magic Conch Shell": {
    eng: "Spongebob's Magic Conch Shell",
    ru: 'Волшебная ракушка Губки Боба',
  },
  'Key:Language Detector': {
    eng: 'Language Detector',
    ru: 'Определитель языка',
  },
  'Key:Salesperson': {
    eng: 'Salesperson',
    ru: 'Продажник',
  },
  'Key:Commit Message Generator': {
    eng: 'Commit Message Generator',
    ru: 'Генератор сообщений для коммитов',
  },
  'Key:Chief Executive Officer': {
    eng: 'Chief Executive Officer',
    ru: 'Генеральный директор',
  },
  'Key:Diagram Generator': {
    eng: 'Diagram Generator',
    ru: 'Генератор диаграмм',
  },
  'Key:Speech-Language Pathologist (SLP)': {
    eng: 'Speech-Language Pathologist (SLP)',
    ru: 'Логопед',
  },
  'Key:Startup Tech Lawyer': {
    eng: 'Startup Tech Lawyer',
    ru: 'Юрист в стартапе',
  },
  'Key:Title Generator for written pieces': {
    eng: 'Title Generator for written pieces',
    ru: 'Генератор заголовков для письменных работ',
  },
  'Key:Product Manager': {
    eng: 'Product Manager',
    ru: 'Менеджер по продукту',
  },
  'Key:Drunk Person': {
    eng: 'Drunk Person',
    ru: 'Пьяный человек',
  },
  'Key:Mathematical History Teacher': {
    eng: 'Mathematical History Teacher',
    ru: 'Учитель истории развития математики',
  },
  'Key:Song Recommender': {
    eng: 'Song Recommender',
    ru: 'Советчик песен',
  },
  'Key:Cover Letter': {
    eng: 'Cover Letter',
    ru: 'Сопроводительное письмо',
  },
  'Key:Technology Transferer': {
    eng: 'Technology Transferer',
    ru: 'Трансфер технологий',
  },
  'Key:Unconstrained AI model DAN': {
    eng: 'Unconstrained AI model DAN',
    ru: 'DAN — модель ИИ без ограничений',
  },
  'Key:Gomoku player': {
    eng: 'Gomoku player',
    ru: 'Игрок в Гомоку',
  },
  'Key:Proofreader': {
    eng: 'Proofreader',
    ru: 'Редактор',
  },
  'Key:Buddha': {
    eng: 'Buddha',
    ru: 'Будда',
  },
  'Key:Muslim imam': {
    eng: 'Muslim imam',
    ru: 'Имам',
  },
  'Key:Chemical reactor': {
    eng: 'Chemical reactor',
    ru: 'Химический реактор',
  },
  'Key:Friend': {
    eng: 'Friend',
    ru: 'Друг',
  },
  'Key:ChatGPT prompt generator': {
    eng: 'ChatGPT prompt generator',
    ru: 'Генератор промтов для ChatGPT',
  },
  'Key:Wikipedia page': {
    eng: 'Wikipedia page',
    ru: 'Страница на «Википедии»',
  },
  'Key:Japanese Kanji quiz machine': {
    eng: 'Japanese Kanji quiz machine',
    ru: 'Японская викторина по кандзи',
  },
  'Key:note-taking assistant': {
    eng: 'note-taking assistant',
    ru: 'Ассистент, ведущий конспект',
  },
  'Key:Language Literary Critic': {
    eng: 'Language Literary Critic',
    ru: 'Литературный критик',
  },
  'Key:Cheap Travel Ticket Advisor': {
    eng: 'Cheap Travel Ticket Advisor',
    ru: 'Консультант по дешевым билетам для путешествий',
  },
  'Key:nothing': {
    eng: 'Do not add',
    ru: 'Не добавлять',
  },
  'Key:talking': {
    eng: 'Neutral, fast',
    ru: 'Нейтральная, быстрая',
  },
  'Key:news': {
    eng: 'News',
    ru: 'Из заставки новостей',
  },
  'Key:medieval': {
    eng: 'Medieval',
    ru: 'Средневековые мотивы',
  },
  'Key:speaker': {
    eng: 'Speaker',
    ru: 'Диктор',
  },
  'Key:gpt-4-1106-preview': {
    eng: 'GPT-4 Turbo',
    ru: 'GPT-4 Turbo',
  },
  'Key:gpt-4-vision-preview': {
    eng: 'GPT-4 Vision',
    ru: 'GPT-4 Vision',
  },
  'Key:gpt-4o': {
    eng: 'GPT-4o',
    ru: 'GPT-4o',
  },
  'Key:gpt-4o-mini': {
    eng: 'GPT-4o mini',
    ru: 'GPT-4o mini',
  },
  'Key:gpt-4': {
    eng: 'GPT-4',
    ru: 'GPT-4',
  },
  'Key:gpt-3.5-turbo-0125': {
    eng: 'GPT-3.5 Turbo',
    ru: 'GPT-3.5 Turbo',
  },
  'Key:claude-3-opus': {
    eng: 'Claude 3 Opus',
    ru: 'Claude 3 Opus',
  },
  'Key:claude-3-sonnet': {
    eng: 'Claude 3 Sonnet',
    ru: 'Claude 3 Sonnet',
  },
  'Key:claude-3-haiku': {
    eng: 'Claude 3 Haiku',
    ru: 'Claude 3 Haiku',
  },
  'Key:claude-3-5-sonnet': {
    eng: 'Claude 3.5 Sonnet',
    ru: 'Claude 3.5 Sonnet',
  },
  'Key:gemini-1.0-pro': {
    eng: '1.0 Pro',
    ru: '1.0 Pro',
  },
  'Key:gemini-1.5-pro': {
    eng: '1.5 Pro',
    ru: '1.5 Pro',
  },
  'Key:gemini-1.5-flash': {
    eng: '1.5 Flash',
    ru: '1.5 Flash',
  },
  'Key:Boolean:true': {
    eng: 'Yes',
    ru: 'Да',
  },
  'Key:Boolean:false': {
    eng: 'No',
    ru: 'Нет',
  },

};
